<template>
  <a @click.prevent="openContactForm" class="dropdown-item is-size-6">
    <span class="icon is-small has-text-danger" v-if="$cazana">
      <i class="fas fa-user-headset"></i>
    </span>
    <span>
      {{ contactText }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'AppHeaderBrandContact',
  computed: {
    contactText() {
      return this.product.slug ? 'Get support on this vehicle' : 'Get support'
    },
    product() {
      if (this.$experian) {
        switch (this.$route.name) {
          case 'autocheck':
            return { slug: 'autocheck', name: 'AutoCheck' }
          case 'companion':
            return { slug: 'companion', name: 'Companion' }
          case 'vehicle-profile':
            return { slug: 'vpp', name: 'Vehicle Profile' }
          default:
            return { slug: null, name: 'General' }
        }
      } else {
        if (this.$route.name === 'companion') {
          return { slug: 'companion', name: 'Companion' }
        } else {
          return { slug: null, name: 'General' }
        }
      }
    },
    formData() {
      return {
        vrm: this.$store.getters[`${this.product.slug}/vrm`],
        vin: this.$store.getters[`${this.product.slug}/vin`],
        description: this.$store.getters[`${this.product.slug}/description`]
      }
    }
  },
  methods: {
    track(action) {
      this.$mxp.track(`app_header_${action}`)
    },
    openContactForm() {
      const data = { ...this.formData, product: this.product.name }
      this.track(`open_contact_form_${this.product.name.toLowerCase()}`)
      this.$modal.open('contact/Contact', data)
    }
  }
}
</script>
